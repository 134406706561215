import React, { useEffect, useState } from "react";
import styles from "./SubmitName.module.css";

const SubmitName = ({ close }) => {
  const [name, setName] = useState("");
  const nameValid = name.trim() !== "" && name.length > 2;
  console.log(nameValid);
  const submitName = () => {
    localStorage.setItem("name", name);
    close();
  };
  useEffect(() => {
    const nameExist = localStorage.getItem("name");
    if (nameExist) {
      setName(nameExist);
    }
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div
        className="w-full p-8 bg-slate-100 flex flex-col gap-4 rounded"
        style={{ maxWidth: "450px" }}
      >
        <input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          type="text"
          className="outline-none p-4"
          style={{ backgroundColor: "rgba()" }}
          placeholder={"أدخل اسمك / Enter your Name"}
        />
        <button
          className={`text-xl enl mx-auto py-3 w-full lg:w-40 rounded ${styles.SubmitBtn}`}
          disabled={!nameValid}
          onClick={() => {
            submitName();
          }}
        >
          Confirm / تأكيد
        </button>
      </div>
    </div>
  );
};

export default SubmitName;
